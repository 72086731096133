import useOnMount from './useOnMount'
import storageManager from '../services/StorageManager'
import { useRef } from 'react'
import { analytics, EVENT_NAMES } from '../services/Analytics/Analytics'
import { MIXTILES_ART_URL } from '../config/config'

const LAST_ACTIVE_KEY = 'lastActiveTime'
const BEFORE_UNLOAD_KEY = 'beforeUnloadTime'
const LAST_ACTION_KEY = 'lastActionKey'

const CRASH_TIME_GAP = 60000
const INTERVAL = 1000

export function setLastAction(lastAction: string) {
  storageManager.set(LAST_ACTION_KEY, lastAction)
}

export default function useCrashLogger() {
  const unloaded = useRef(false)

  const onUnload = () => {
    unloaded.current = true
    storageManager.set(BEFORE_UNLOAD_KEY, Date.now())
    analytics.track(EVENT_NAMES.APP_CLOSED, {
      lastAction: storageManager.get<string>(LAST_ACTION_KEY),
      page: window.location.pathname,
    })
  }

  useOnMount(() => {
    const lastActiveTime = storageManager.get<number>(LAST_ACTIVE_KEY)
    const beforeUnloadTime = storageManager.get<number>(BEFORE_UNLOAD_KEY) || 0
    const time = Date.now()

    // If the user closed or refreshed the page in non-forceful way it will call unload
    // and the time of the unload will be after the time of the last active
    // if the browser crashed, unload will not be called and the time of the last active
    // will be after the time of the unload
    // we check the time gap to see that the user returned to the page after a few seconds and not hours/days in case
    // he just closed the browser from the app task manager
    if (
      !window.location.pathname.startsWith(MIXTILES_ART_URL) &&
      lastActiveTime &&
      beforeUnloadTime &&
      time - lastActiveTime < CRASH_TIME_GAP &&
      time - lastActiveTime < time - beforeUnloadTime
    ) {
      analytics.track(EVENT_NAMES.SUSPECTED_CRASH, {
        lastActiveTime: time - lastActiveTime,
        unloadTime: time - beforeUnloadTime,
        lastAction: storageManager.get<string>(LAST_ACTION_KEY),
        page: window.location.pathname,
      })
    }

    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('unload', onUnload)

    const interval = setInterval(() => {
      if (!unloaded.current) {
        storageManager.set(LAST_ACTIVE_KEY, Date.now())
      }
    }, INTERVAL)

    return () => {
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('unload', onUnload)
      clearInterval(interval)
    }
  })
}
